@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400&family=Poppins:ital,wght@0,100;0,200;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Manrope:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

.App {
    font-family: "Mitr";
}

.datagridHeader {
    /* font-family: Poppins; */
    font-weight: bolder;
    font-size: 16px;
    /* line-height: 34px; */
    color: #670150;
}

.datagridContent {
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 33px;
/* or 144% */

text-align: right;
width:20em;

color: #393C47;

}

.curved {
      border-bottom-left-radius: 50% 10%;
  border-bottom-right-radius: 50% 10px;
  border-top-left-radius: 0;
  transform: skew(-5deg, -2deg);
  margin: 2em 3em;
  box-shadow: 8px 1px 12px rgba(0,0,0,.3)
}
